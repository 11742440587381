<template>
  <v-menu offset-y
          offset-x
          left
          content-class="elevation-6"
          transition="slide-y-transition">
    <template #activator="{ on, attrs }">
      <i
        v-bind="attrs"
        class="g-icon g-icon-globe"
        :class="{ active: attrs['aria-expanded'] === 'true' }"
        v-on="on" />
    </template>
    <v-list v-if="languages.length !== 0">
      <v-list-item
        v-for="(language, index) in languages"
        :key="index"
        :class="{ active: language.name === currentLanguage }"
        @click="$emit('set-language', language.name)">
        <v-list-item-title
          class="pl-1 pr-2 text--secondary">
          {{ language.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-list v-else>
      <v-list-item>
        <v-list-item-title class="pl-1 pr-5 text--secondary">
          {{ 'edit_modal_no_translations' | translate }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    currentLanguage: {
      type: String,
      required: true,
    },
  },
  computed: {
    languages() {
      return this.$store.getters.languages;
    },
  },
};
</script>

<style lang="scss" scoped>
.g-icon-globe {
  position: absolute;
  // spacing same as the label
  top: 8px;
  right: 14px;
  font-size: 1.2em;
  cursor: pointer;

  &:hover,
  &.active {
    color: $brand;
  }
}

.v-list {
  max-height: 15rem;
  &-item {
    background-color: white;

    &.active{
      background-color: lighten($font-secondary-1, 3%);
    }
  }
  &-item__title {
    font-size: 0.875em;
  }
}
</style>
