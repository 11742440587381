<template>
  <div>
    <app-filter :filter-config="gamesFilterConfig"
                @filtersChanged="fetchData"
                @companyChanged="fetchProvidersAndAggregators" />
    <div class="games-content-wrapper fluid-container">
      <div class="games-wrapper">
        <div v-if="$route.params.companyId"
             class="promoted-games">
          <div class="promoted-games-header">
            <h1 class="promoted-games-headline">
              {{ "all_games_promoted_games" | translate }}
            </h1>
          </div>
          <draggable v-if="promotedGames.length > 0"
                     v-model="draggablePromotedGames"
                     v-bind="dragOptions"
                     class="promoted-games-container">
            <div v-for="game in draggablePromotedGames"
                 :key="game.id"
                 class="casino-game">
              <div ref="draggablePromotedGames"
                   class="casino-game-image"
                   :data-background-image-urls="`url('${game.stickerImageUrl}'), url('${gameImageUrl(game)}')`">
                <div v-if="game.new"
                     class="new-label">
                  <label>
                    {{ 'all_games_new_game_label'|translate }}
                  </label>
                </div>
              </div>
              <div class="casino-game-content">
                <div class="casino-game-title">
                  {{ game.title }}
                </div>
                <div v-if="!dragOptions.disabled"
                     class="casino-game-grab g-icon g-icon-grab" />
                <div class="casino-game-controls">
                  <div class="casino-game-buttons">
                    <div class="game-promoted"
                         @click="promoteGame(game)">
                      <span class="g-icon g-icon-check-a" />
                      <span class="game-promoted-label">
                        {{ 'all_games_promoted_game_label'|translate }}
                      </span>
                    </div>
                    <div class="game-edit"
                         @click="showModal(game)">
                      <span class="g-icon g-icon-edit" />
                      <span class="game-promoted-label">
                        {{ 'edit_modal_edit_title'|translate }}
                      </span>
                    </div>
                  </div>
                  <div class="casino-game-statuses">
                    <div class="casino-game-status">
                      <span class="g-icon g-icon-circle-b"
                            :class="{'disabled-orange' : !game.active}" />
                      <label class="casino-game-status-label">
                        {{ 'edit_modal_active_label'|translate }}
                      </label>
                    </div>
                    <div class="casino-game-status">
                      <span class="g-icon g-icon-circle-b"
                            :class="{'disabled-orange' : !game.enabled}" />
                      <label class="casino-game-status-label">
                        {{ 'filter_enabled_dropdown'|translate }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="game-empty">
              {{ 'all_games_select_promoted_game'|translate }}
            </div>
          </Draggable>
          <div v-if="promotedGames.length === 0"
               class="no-data-promoted-placeholder">
            <h1 v-if="$route.params.companyId"
                class="no-data-message">
              <label>
                {{ "promoted_games_no_games" | translate }}
              </label>
            </h1>
          </div>
        </div>
        <div v-if="$route.params.companyId"
             class="casino-games">
          <div class="casino-games-header">
            <h1 class="casino-games-headline">
              {{ "all_games_games" | translate }}
            </h1>
          </div>
          <draggable v-if="$route.params.companyId"
                     v-model="draggableGames"
                     v-bind="dragOptions"
                     class="casino-games-container">
            <div v-for="game in draggableGames"
                 :key="game.id"
                 class="casino-game">
              <div ref="draggableGames"
                   class="casino-game-image"
                   :data-background-image-urls="`url('${game.stickerImageUrl}'), url('${gameImageUrl(game)}')`">
                <div v-if="game.new"
                     class="new-label">
                  <label>
                    {{ 'all_games_new_game_label'|translate }}
                  </label>
                </div>
              </div>
              <div class="casino-game-content">
                <div class="casino-game-title">
                  {{ game.title }}
                </div>
                <div v-if="!dragOptions.disabled"
                     class="casino-game-grab g-icon g-icon-grab" />
                <div class="casino-game-controls">
                  <div class="casino-game-buttons">
                    <div class="game-promoted"
                         @click="promoteGame(game)">
                      <span class="g-icon"
                            :class="[game.promoted ? 'g-icon-check-a' : 'g-icon-circle']" />
                      <span v-if="!game.promoted"
                            class="game-promoted-label">
                        {{ 'all_games_promote_game_label'|translate }}
                      </span>
                      <span v-else
                            class="game-promoted-label">
                        {{ 'all_games_promoted_game_label'|translate }}
                      </span>
                    </div>
                    <div class="game-edit"
                         @click="showModal(game)">
                      <span class="g-icon g-icon-edit" />
                      <span class="game-promoted-label">
                        {{ 'edit_modal_edit_title'|translate }}
                      </span>
                    </div>
                  </div>
                  <div class="casino-game-statuses">
                    <div class="casino-game-status">
                      <span class="g-icon g-icon-circle-b"
                            :class="{'disabled-orange' : !game.active}" />
                      <label class="casino-game-status-label">
                        {{ 'edit_modal_active_label'|translate }}
                      </label>
                    </div>
                    <div class="casino-game-status">
                      <span class="g-icon g-icon-circle-b"
                            :class="{'disabled-orange' : !game.enabled}" />
                      <label class="casino-game-status-label">
                        {{ 'filter_enabled_dropdown'|translate }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Draggable>
        </div>
      </div>
      <div v-if="!games.length || !$route.params.companyId"
           class="no-data-placeholder">
        <h1 class="no-data-message">
          <label>
            {{
              $route.params.companyId
                ? "all_games_no_games"
                : "all_games_select_company" | translate
            }}
          </label>
        </h1>
      </div>
    </div>
    <edit-modal :is-modal-open.sync="isModalOpen" />
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import { mapGetters, mapActions } from 'vuex';
import { useIntersectionObserver } from '@vueuse/core';
import EditModal from '@/components/EditModal';
import AppFilter from '@/components/AppFilter';

export default {
  name: 'Games',
  components: {
    EditModal,
    Draggable,
    AppFilter,
  },
  data() {
    return {
      reorderedGames: null,
      reorderedPromotedGames: null,
      isModalOpen: false,
      dragOptions: {
        disabled: false,
        forceFallback: true,
        fallbackTolerance: 3,
        scrollSensitivity: 200,
        scrollSpeed: 45,
        handle: '.casino-game-grab',
      },
    };
  },
  computed: {
    ...mapGetters([
      'gamesFilterConfig',
      'isReorderingAvailable',
      'games',
      'promotedGames',
      'getFilterParams',
    ]),
    gameImageUrl() {
      return (game) => (this.getFilterParams.mobile ? game.imageCompanyMobileUrl : game.imageCompanyUrl)
        || game.imageUrl;
    },

    draggableGames: {
      get() {
        return this.reorderedGames || this.games;
      },
      async set(value) {
        this.toggleLoader(true);

        const reorderedGamesIds = value.map((game) => game.id);
        await this.updateGamesOrder(reorderedGamesIds);
        this.reorderedGames = value;

        this.toggleLoader(false);
      },
    },
    draggablePromotedGames: {
      get() {
        return this.reorderedPromotedGames || this.promotedGames;
      },
      async set(value) {
        this.toggleLoader(true);

        const reorderedPromotedGamesIds = value.map((game) => game.id);
        await this.updatePromotedGamesOrder(reorderedPromotedGamesIds);
        this.reorderedPromotedGames = value;

        this.toggleLoader(false);
      },
    },
  },
  watch: {
    draggableGames() {
      this.$nextTick(() => {
        if (!this.$refs.draggableGames?.length) return;
        this.observeElementsItersection(this.$refs.draggableGames);
      });
    },
    draggablePromotedGames() {
      this.$nextTick(() => {
        if (!this.$refs.draggablePromotedGames?.length) return;
        this.observeElementsItersection(this.$refs.draggablePromotedGames);
      });
    },
  },
  updated() {
    this.dragOptions.disabled = !this.isReorderingAvailable;
  },
  created() {
    window.addEventListener('visibilitychange', this.visibilityHandler);
  },
  beforeDestroy() {
    window.removeEventListener('visibilitychange', this.visibilityHandler);
  },
  methods: {
    ...mapActions([
      'toggleLoader',
      'getCasinoGames',
      'getAggregators',
      'getProviders',
      'updateGamePromotedStatus',
      'updateGamesOrder',
      'updatePromotedGamesOrder',
      'updateGamesIds',
      'updatePromotedGamesIds',
    ]),
    async fetchData(params = this.getFilterParams) {
      if (!params.companyId) return;

      this.resetGamesOrder();

      this.toggleLoader(true);
      await this.getCasinoGames(params);
      this.toggleLoader(false);
    },
    async fetchProvidersAndAggregators() {
      if (!this.getFilterParams.companyId) return;

      this.toggleLoader(true);
      await this.getAggregators();
      await this.getProviders();
      this.toggleLoader(false);
    },
    async promoteGame(game) {
      this.toggleLoader(true);

      await this.updateGamePromotedStatus({
        gameId: game.id,
        isPromoted: !game.promoted,
      });

      this.resetGamesOrder();

      this.toggleLoader(false);
    },

    resetGamesOrder() {
      this.reorderedGames = null;
      this.reorderedPromotedGames = null;
    },

    async showModal(game) {
      this.$modal.show('edit-modal', {
        companyId: this.getFilterParams.companyId,
        gameId: game.id,
      });
    },
    visibilityHandler(e) {
      const element = e.target;
      if (element.visibilityState === 'hidden') return;

      this.fetchData();
    },

    observeElementsItersection(targetElements) {
      targetElements.forEach((el) => {
        const { stop } = useIntersectionObserver(
          el,
          ([{ isIntersecting }]) => {
            if (isIntersecting) {
              // eslint-disable-next-line no-param-reassign
              el.style.backgroundImage = el.dataset.backgroundImageUrls;
              stop();
            }
          },
          {
            root: document,
            rootMargin: '0px 0px 500px 0px',
          },
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.games-content-wrapper {
  --game-height: 6rem;
}

.promoted-games-headline,
.casino-games-headline {
  font-size: $fs-300;
  font-weight: bold;
  text-transform: uppercase;
  color: $font-primary-1;
  margin-bottom: 0.625rem;
  margin-top: 1rem;
}

.casino-games {
  margin-top: 1.25em;
}

.promoted-games-container,
.casino-games-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.625em;
  padding-bottom: 1.25em;

  @include breakpoint-medium {
      grid-template-columns: 1fr 1fr;
  }

  @include breakpoint-large {
      grid-template-columns: 1fr 1fr 1fr;
  }
}

.game-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--game-height);
  margin-bottom: 0.625em;
  color: $font-primary-1;
  font-size: 0.875em;
  border: 1px dashed $bg-400;
  border-radius: $br-sm;
  background-color: transparent;
  box-shadow: none;
}

.casino-game {
  position: relative;
  height: var(--game-height);
  display: flex;
  border-radius: $br-sm;
  background-color: #fff;
  box-shadow: $shadow-outline;

  &-image {
    position: relative;
    height: 100%;
    width: 30%;
    background-color: $bg-300;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .new-label {
      position: absolute;
      top: 0;
      right: 0;
      width: 4em;
      height: 1.875em;
      color: #fff;
      text-align: center;
      line-height: 1.875em;
      background-color: rgba($brand, 0.9);

      label {
        font-size: $fs-200;
      }
    }
  }

  &-content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0.25em 0.75em;
    color: $font-primary-2;

    .casino-game-title {
      margin-top: 0.25em;
      font-size: $fs-500;
    }
  }

  &-grab {
    position: absolute;
    top: 0.625em;
    right: 0.75em;
    transform: rotate(90deg);
    cursor: move;
    @include increaseClickability(0.75rem);
  }

  &-controls {
    display: flex;
    margin-top: auto;

    .g-icon {
      vertical-align: middle;
      cursor: pointer;
      transition: color 200ms $ease-out-cubic;

      &:hover {
        color: darken($font-primary-3, 30%);
      }
    }
  }

  &-statuses {
    margin-left: auto;
    display: flex;
    align-items: center;

  .casino-game-status {
    text-align: center;

    &:first-child {
      margin-right: 0.5em;
    }

    .g-icon {
      color: $positive;
      vertical-align: bottom;

      &.disabled-orange {
        color: $warning;
      }
    }

    .casino-game-status-label {
      display: block;
      font-size: $fs-200;
    }
  }
  }

  &-buttons {
    display: flex;
    margin-right: 0.75rem;

    .game-promoted,
    .game-edit {
      text-align: center;
    }

    .game-promoted {
      padding-right: 0.625rem;

      &-label {
        display: block;
        font-size: $fs-200;
      }
    }

    .game-edit {
      border-left: thin solid $font-primary-3;
      padding-left: 0.62rem;
    }
  }
}

.no-data-placeholder,
.no-data-promoted-placeholder {
  padding: 3rem 0;
  text-align: center;

  .no-data-message {
    width: 100%;
    height: var(--game-height);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $font-primary-1;
    font-size: $fs-300;
    font-weight: bold;
    border: thin dashed $font-primary-3;
    border-radius: $br-sm;
  }
}

.no-data-promoted-placeholder {
  height: 0;

  .no-data-message {
    margin-top: -3rem;
  }
}
</style>
