<template>
  <modal name="edit-modal"
         height="auto"
         scrollable
         :adaptive="true"
         @before-open="beforeOpen"
         @before-close="beforeClose">
    <div class="modal-content-wrapper">
      <div class="modal-header">
        <div class="modal-header-title">
          {{ 'edit_modal_edit_title' | translate }}
        </div>
        <div class="modal-header-close"
             @click="cancelUpdate()">
          <span class="g-icon g-icon-close-a" />
        </div>
      </div>
      <div class="modal-content">
        <modal-input
          key="originName"
          class="modal-input"
          :value="modalGame.title"
          translation-key="edit_modal_game_origin_name"
          name="originName"
          disabled />
        <div class="modal-row-container">
          <modal-input
            key="displayName"
            v-model="selectedGameLocale.title"
            v-validate="'required|max:50'"
            class="modal-input"
            :disabled="isLanguageDataLoading"
            translation-key="edit_modal_game_display_name"
            name="displayName"
            :error="errors.first('displayName')" />
          <modal-language-dropdown
            :current-language="currentLanguage"
            @set-language="setSelectedGameLocale" />
        </div>
        <div class="modal-row-container">
          <modal-textarea
            key="gameDescription"
            v-model="selectedGameLocale.description"
            v-validate="'max:50000'"
            class="modal-input"
            :disabled="isLanguageDataLoading"
            translation-key="edit_modal_game_description"
            name="gameDescription"
            :error="errors.first('gameDescription')" />
          <modal-language-dropdown
            :current-language="currentLanguage"
            @set-language="setSelectedGameLocale" />
        </div>
        <modal-input v-if="modalGame.category"
                     key="categoryTitle"
                     class="modal-input"
                     :value="categoryDisplayLabel"
                     translation-key="edit_modal_category_title"
                     name="categoryTitle"
                     :disabled="true" />
        <div class="modal-input-image modal-input">
          <div class="modal-input-image__image"
               :style="{ 'background-image': `url('${modalGame.imageUrl}')` }" />
          <modal-input key="imageUrl"
                       :value="modalGame.imageUrl"
                       translation-key="edit_modal_image_url"
                       :disabled="true"
                       class="modal-input-image__input"
                       name="imageUrl" />
        </div>
        <div v-if="filterParams.mobile"
             class="modal-input-image modal-input">
          <div class="modal-input-image__image"
               :style="{ 'background-image': `url('${validImageCompanyMobileUrl}')` }" />
          <modal-input key="mobileImageUrl"
                       v-model="modalGame.imageCompanyMobileUrl"
                       v-validate="'image_url'"
                       :error="errors.first('mobileImageUrl')"
                       translation-key="edit_modal_mobile_image_url"
                       class="modal-input-image__input"
                       name="mobileImageUrl" />
        </div>
        <div v-else
             class="modal-input-image modal-input">
          <div class="modal-input-image__image"
               :style="{ 'background-image': `url('${validImageCompanyUrl}')` }" />
          <modal-input key="desktopImageUrl"
                       v-model="modalGame.imageCompanyUrl"
                       v-validate="'image_url'"
                       translation-key="edit_modal_desktop_image_url"
                       :error="errors.first('desktopImageUrl')"
                       class="modal-input-image__input"
                       name="desktopImageUrl" />
        </div>
        <div class="modal-input-image modal-input">
          <div class="modal-input-image__image"
               :style="{ 'background-image': `url('${modalGame.stickerImageUrl}'), url('${gameImageUrl}')` }" />
          <modal-input key="stickerImageUrl"
                       v-model="modalGame.stickerImageUrl"
                       v-validate="'image_url'"
                       translation-key="edit_modal_sticker_image_url"
                       :error="errors.first('stickerImageUrl')"
                       class="modal-input-image__input"
                       name="stickerImageUrl" />
        </div>
        <div class="game-items">
          <div class="game-item">
            <div class="game-item-title">
              <span class="game-item-text">
                <span class="g-icon"
                      :class="[modalGame.new ? g_icon_checkbox_c : g_icon_checkbox]"
                      @click="modalGame.new = !modalGame.new" />
                <span class="game-item-check-label">
                  {{ 'edit_modal_new_label' | translate }}
                </span>
              </span>
            </div>
          </div>
          <div class="game-item">
            <div class="game-item-title">
              <span class="game-item-text">
                <span class="g-icon"
                      :class="[modalGame.promoted ? g_icon_checkbox_c : g_icon_checkbox]"
                      @click="modalGame.promoted = !modalGame.promoted" />
                <span class="game-item-check-label">
                  {{ 'edit_modal_promoted_label' |translate }}
                </span>
              </span>
            </div>
          </div>
          <div class="game-item">
            <div class="game-item-title">
              <span class="game-item-text">
                <span class="g-icon"
                      :class="[modalGame.popular ? g_icon_checkbox_c: g_icon_checkbox]"
                      @click="modalGame.popular = !modalGame.popular" />
                <span class="game-item-check-label">
                  {{ 'edit_modal_popular_label' | translate }}
                </span>
              </span>
            </div>
          </div>
          <div class="game-item">
            <div class="game-item-title">
              <span class="game-item-text">
                <span class="g-icon"
                      :class="[modalGame.active ? g_icon_checkbox_c : g_icon_checkbox]"
                      @click="modalGame.active = !modalGame.active" />
                <span class="game-item-check-label">
                  {{ 'edit_modal_active_label' | translate }}
                </span>
              </span>
            </div>
          </div>
          <div class="game-item">
            <div class="game-item-title">
              <span class="game-item-text">
                <span class="g-icon"
                      :class="[modalGame.standalone ? g_icon_checkbox_c : g_icon_checkbox]"
                      @click="modalGame.standalone = !modalGame.standalone" />
                <span class="game-item-check-label">
                  {{ 'edit_modal_standalone_label' | translate }}
                </span>
              </span>
            </div>
          </div>
          <div class="game-item">
            <div class="game-item-title">
              <span class="game-item-text">
                <span class="g-icon"
                      :class="[modalGame.tournament ? g_icon_checkbox_c : g_icon_checkbox]"
                      @click="modalGame.tournament = !modalGame.tournament" />
                <span class="game-item-check-label">
                  {{ 'edit_modal_tournament_label' | translate }}
                </span>
              </span>
            </div>
          </div>
        </div>

        <div class="game-info">
          <div class="game-info-text">
            <div class="game-info-label">
              {{ 'edit_modal_id_label' | translate }}:
            </div>
            <span>
              {{ modalGame.id }}
            </span>
          </div>
          <div class="game-info-text">
            <div class="game-info-label">
              {{ 'edit_modal_priority_label' | translate }}:
            </div>
            <span>
              {{ gamePriority }}
            </span>
          </div>
          <div class="game-info-text">
            <div class="game-info-label">
              {{ 'edit_modal_aggregator_label' | translate }}:
            </div>
            <span v-if="modalGame.aggregator">
              {{ aggregatorDisplayLabel }}
            </span>
          </div>
          <div class="game-info-text">
            <div class="game-info-label">
              {{ 'edit_modal_provider_label' | translate }}:
            </div>
            <span>
              {{ providerDisplayLabel }}
            </span>
          </div>
          <div v-if="modalGame.createdAt"
               class="game-info-text">
            <div class="game-info-label">
              {{ 'edit_modal_createdAt_label' | translate }}
            </div>
            <span>
              {{ createdAtDisplayLabel }}
            </span>
          </div>
          <div v-if="modalGame.updatedAt!== null"
               class="game-info-text">
            <div class="game-info-label">
              {{ 'edit_modal_updatedAt_label' | translate }}
            </div>
            <span>
              {{ updatedAtDisplayLabel }}
            </span>
          </div>
        </div>
        <div class="g-toggle g-toggle-europa">
          <input id="round-toggle"
                 class="g-toggle-input"
                 type="checkbox"
                 :checked="enabled === 'Enabled'"
                 @click="modalGame.enabled = !modalGame.enabled">
          <label class="g-toggle-label"
                 for="round-toggle">
            {{ enabled }}
          </label>
        </div>
      </div>
      <div class="modal-footer">
        <button class="g-button g-button-earth"
                @click="cancelUpdate()">
          <span class="g-button-title">{{ 'edit_modal_cancel_edit' | translate }}</span>
        </button>
        <button
          class="g-button g-button-earth"
          :disabled="isDisabled"
          @click="updateGameData()">
          <span class="g-button-title">{{
            'edit_modal_update_game' | translate
          }}</span>
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import dayjs from '@/utility/dayjs';
import _capitalize from 'lodash/capitalize';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import { mapState, mapGetters, mapActions } from 'vuex';
import ModalInput from '@/components/shared/ModalInput';
import ModalTextarea from '@/components/shared/ModalTextarea';
import ModalLanguageDropdown from '@/components/shared/ModalLanguageDropdown';

export default {
  name: 'EditModal',
  components: {
    ModalInput,
    ModalTextarea,
    ModalLanguageDropdown,
  },
  data() {
    return {
      params: {},
      modalGame: {},
      openedGame: {},
      currentLanguage: 'en',
      isLanguageDataLoading: true,
      g_icon_checkbox: 'g-icon-checkbox',
      g_icon_checkbox_c: 'g-icon-checkbox-c',
      isDropdownOpen: false,
    };
  },
  computed: {
    ...mapState(['filterParams']),
    ...mapGetters(['gameLanguageData', 'initialGameLanguageData', 'casinoGame']),
    aggregatorDisplayLabel() {
      return _capitalize(this.modalGame.aggregator);
    },
    categoryDisplayLabel() {
      return _capitalize(this.modalGame.category);
    },
    createdAtDisplayLabel() {
      return this.modalGame.createdAt ? dayjs(this.modalGame.createdAt).format('LLL') : 'N/A';
    },
    enabled() {
      return this.modalGame.enabled ? 'Enabled' : 'Disabled';
    },
    gamePriority() {
      return this.filterParams.mobile ? this.modalGame.priorityMobile : this.modalGame.priority;
    },
    isDisabled() {
      return !!this.errors.items.length
        || (!this.isGameModified && !this.isLanguageDataModified)
        || this.isLanguageDataLoading;
    },
    isGameModified() {
      return !_isEqual(this.modalGame, this.openedGame);
    },
    isLanguageDataModified() {
      return !_isEqual(
        this.initialGameLanguageData,
        this.gameLanguageData,
      );
    },
    providerDisplayLabel() {
      return _capitalize(this.modalGame.provider);
    },
    selectedGameLocale: {
      get() {
        return this.gameLanguageData[this.currentLanguage];
      },
      set(value) {
        this.gameLanguageData[this.currentLanguage] = value;
      },
    },
    updatedAtDisplayLabel() {
      return this.modalGame.updatedAt ? dayjs(this.modalGame.updatedAt).format('LLL') : 'N/A';
    },
    validImageCompanyUrl() {
      return !this.errors.first('desktopImageUrl') && this.modalGame.imageCompanyUrl;
    },
    validImageCompanyMobileUrl() {
      return !this.errors.first('mobileImageUrl') && this.modalGame.imageCompanyMobileUrl;
    },
    gameImageUrl() {
      const url = this.filterParams.mobile ? this.validImageCompanyMobileUrl : this.validImageCompanyUrl;
      return url || this.modalGame.imageUrl;
    },
  },
  methods: {
    ...mapActions(['getCasinoGame', 'clearCasinoGame', 'toggleLoader']),
    beforeOpen({ params }) {
      this.$validator.resume();

      this.assignGame(params);
    },
    beforeClose() {
      this.$validator.pause();
      this.isDropdownOpen = false;

      this.clearCasinoGame();
      this.modalGame = {};

      this.resetToDefaultLanguage();
      this.$emit('update:isModalOpen', false);
    },
    async assignGame(params) {
      this.toggleLoader(true);
      await this.getCasinoGame({ companyId: params.companyId, gameId: params.gameId });
      this.toggleLoader(false);

      this.modalGame = _cloneDeep(this.casinoGame);
      this.openedGame = _cloneDeep(this.casinoGame);
      this.params = {
        companyId: this.$route.params.companyId,
        productId: this.modalGame.id,
      };
      this.getGameLanguageData();
    },
    updateGameData() {
      if (this.isGameModified) {
        this.updateGame();
      }
      if (this.isLanguageDataModified) {
        this.updateGameLocales();
      }
    },
    updateGame() {
      const gameData = {
        params: this.params,
        game: {
          new: this.modalGame.new,
          promoted: this.modalGame.promoted,
          groups: this.modalGame.groups,
          popular: this.modalGame.popular,
          active: this.modalGame.active,
          standalone: this.modalGame.standalone,
          tournament: this.modalGame.tournament,
          enabled: this.modalGame.enabled,
          imageCompanyUrl: this.modalGame.imageCompanyUrl || null,
          imageCompanyMobileUrl: this.modalGame.imageCompanyMobileUrl || null,
          stickerImageUrl: this.modalGame.stickerImageUrl || null,
        },
      };

      return this.$store.dispatch('updateGame', gameData).then(() => {
        this.$modal.hide('edit-modal');
        this.isDropdownOpen = false;
      });
    },
    cancelUpdate() {
      this.isDropdownOpen = false;
      return this.$modal.hide('edit-modal');
    },
    setSelectedGameLocale(language) {
      this.currentLanguage = language;
    },
    updateGameLocales() {
      const isLanguageModified = (initial, changed) => !_isEqual(initial, changed);
      const extractModifiedLanguages = (data, initialData) => {
        const modified = [];
        data.forEach((changedLanguage, i) => {
          const initialLanguage = initialData[i];
          if (isLanguageModified(initialLanguage, changedLanguage)) {
            modified.push(changedLanguage);
          }
        });
        return modified;
      };

      const gameLanguageDataArray = Object.values(this.gameLanguageData);
      const initialGameLanguageDataArray = Object.values(this.initialGameLanguageData);

      const modifiedLanguages = extractModifiedLanguages(gameLanguageDataArray, initialGameLanguageDataArray);

      this.$store.dispatch('updateGameLocales', { ...this.params, gameLocales: modifiedLanguages }).then(() => {
        this.$modal.hide('edit-modal');
        this.isDropdownOpen = false;
      });
    },
    getGameLanguageData() {
      this.isLanguageDataLoading = true;
      this.$store.dispatch('getGameLanguageData', this.params).then(() => {
        this.isLanguageDataLoading = false;
      });
    },
    resetToDefaultLanguage() {
      this.currentLanguage = 'en';
      this.selectedGameLocale = {
        en: {
          title: '',
          description: '',
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/styles/components/_modal";

.modal-row-container {
  position: relative;
}

.modal-input-image {
  display: flex;

  &__image {
    flex: 1;
    max-width: 20%;
    margin-right: 1rem;
    border: thin solid $bg-300;
    background-color: $bg-200;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  &__input {
    flex: 1;
  }
}

.game-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;

  .game-item {
    position: relative;
    border: thin solid $bg-300;

    .game-item-text {
      width: 100%;
      display: block;
      padding: 0.625em 0.625em 0.325em 0.625em;
    }

    .game-item-check-label {
      display: inline-block;
      font-size: $fs-200;
      font-weight: bold;
      padding-left: 1rem;
      vertical-align: top;
    }

    .g-icon {
      display: inline-block;
      color: $brand-lighter;
      cursor: pointer;
    }
  }
}

.game-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.5rem;
  column-gap: 0.75rem;

  &-text {
    display: flex;
    font-size: $fs-200;
    line-height: 1.3;
  }

  &-label {
    display: inline-block;
    font-weight: bold;
    flex: 1;
    margin-right: 0.5rem;
  }

  span {
    flex: 2;
    opacity: 0.8;
  }
}

.g-toggle {
  display: block;
  margin-top: 2rem;

  label {
    font-weight: bold;
    font-size: $fs-200;
    vertical-align: top;
  }
}
</style>
